<script setup>
import {
    currentWarehouse, 
    itemsData, 
    takeoutSelectedCount, 
    selectedCountPerCategory, 
    MANUAL_ITEMS_CATEGORY,
    expansionPanelsState,
    withdrawMode,
    WITHDRAW_MODE_REGULAR
} from './withdraw'
import Item from './Item.vue';

const globalText = currentWarehouse.globalNote?.split('\n');

</script>

<template>
    <div v-if="withdrawMode === WITHDRAW_MODE_REGULAR && currentWarehouse.takeout_list">
        <div style="float: right; margin-right: 1.8rem; margin-top: 1.9rem; direction: rtl;">
            <p style="font-size: 1.7rem; vertical-align: top; line-height: 0.55;">
                רשימת ציוד
            </p>
            <p style="color: #534341; line-height: 3;">
                <span style="margin-left: 3px;">
                    הוכנה ע״י
                </span>
                <span>
                    {{ currentWarehouse.takeout_list_creator }}
                </span>
            </p>
        </div>
        <div style="float: left; margin-left: 1.8rem; margin-top: 1.9rem;">
            <span style="display: flex; font-size: 1rem; font-weight: bold; vertical-align: top; line-height: 1;">
                <p style="color: #A8372C;">{{ takeoutSelectedCount }}</p>
                <p style="color: #410001;">/{{ currentWarehouse.takeout_list.length }}</p>
            </span>
            <p  v-if="itemsData[MANUAL_ITEMS_CATEGORY]" style="color: #857370; font-size: 0.9rem;">
                מחוץ לרשימה: {{ itemsData[MANUAL_ITEMS_CATEGORY].length }}
            </p>
        </div>
        
        <v-expansion-panels multiple style="padding-top: 1rem;" v-model="expansionPanelsState">
            <v-expansion-panel v-for="[category, items] of Object.entries(itemsData)" :elevation="0"
                :class="{ 'out-of-list-panel': category == MANUAL_ITEMS_CATEGORY }">
                <v-expansion-panel-title expand-icon="mdi-menu-left" collapse-icon="mdi-menu-down">
                    <span class="font-weight-black" style="position: absolute; right: 50px; color: #857370; ">
                        {{ category }}
                    </span>
                    <span style="display: flex; font-size: 0.9rem; font-weight: bold; vertical-align: top; line-height: 1;">
                        <p style="color: #A8372C;">{{ selectedCountPerCategory[category] }}</p>
                        <p v-if="category != MANUAL_ITEMS_CATEGORY" style="color: #7f8570;">/{{ items.length }}</p>
                    </span>
                </v-expansion-panel-title>

                <v-expansion-panel-text v-for="item in items">
                    <Item :item="item" :manual="item.details.item_category == MANUAL_ITEMS_CATEGORY" style="margin-bottom: 15px;"></Item>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
    <div v-else-if="itemsData[MANUAL_ITEMS_CATEGORY]" style="margin-top: 1.9rem;">
        <div v-for="item in itemsData[MANUAL_ITEMS_CATEGORY]">
            <Item :item="item" :manual="item.details.item_category == MANUAL_ITEMS_CATEGORY"></Item>
            <v-divider style="border-width: 0.8px; color: #857370; margin-block: 1rem;"></v-divider>
        </div>
    </div>
    <div class="Botom-note-container">
        <div class="note-title">הערות נוספות:</div>
        <div v-for="globalDetails in globalText">
            {{ globalDetails }}
        </div>
    </div>
    
</template>

<style scoped>


.out-of-list-panel {
    background: #FFFBFF !important;
}

.v-expansion-panel {
    border-top-style: solid !important;
    border-top-width: thin !important;
    border-color: #857370 !important;
}

.v-expansion-panel:last-child {
    border-bottom-style: solid;
    border-bottom-width: thin;
}

.v-expansion-panel--active:last-child {
    padding-bottom: 16px;
}

.v-expansion-panel::after {
    border-top-style: none;
}

.v-expansion-panel-text>>>.v-expansion-panel-text__wrapper {
    padding: 0 !important;
}

.v-expansion-panel-title>>>.v-expansion-panel-title__icon {
    color: #857370 !important;
}

.v-expansion-panel-title>>>.v-expansion-panel-title__overlay {
    background-color: transparent;
}

.Botom-note-container{
    direction: rtl;
    margin: 1rem;
    padding: 2rem;
}

.note-title{
    font-size: 1.6rem;
}

.note-description{
    padding:0.3rem;
}
</style>