<script setup>
import {
    init, selectionMode, ITEMS_SELECTION_MODE, TRANSFER_SELECTION_MODE, currentWarehouse,
    selectedItems, itemsCategories, goTransferSelectionMode, exitTransferSelectionMode, postItems,
    expansionPanelsState, searchModal, loading
} from './transfer'
import { useRouter } from 'vue-router';
import { computed } from 'vue'
import AppBar from '../AppBar.vue';
import Item from './Item.vue';
import Loading from '../Loading.vue';

const props = defineProps({
    warehouse: {
        type: Object,
        required: true
    },
    categorized: {
        type: Boolean,
        required: false
    },
    backRoute: {
        type: String,
        required: true
    }
})

const router = useRouter()

const goBack = () => {
    if (selectionMode.value == TRANSFER_SELECTION_MODE) {
        exitTransferSelectionMode()
    } else {
        router.push(props.backRoute)
    }
}

const selectAll = () => {
    selectedItems.value = currentWarehouse.items.map((item) => item.id)
}

const submit = async () => {
    if (selectionMode.value == ITEMS_SELECTION_MODE) {
        goTransferSelectionMode()
    } else {
        loading.value = true
        await postItems()
        loading.value = false
        router.push(props.backRoute)
    }
}


init(props.warehouse, props.categorized || false)

const itemsToShow = computed(() => {
    if (props.categorized) {
        return itemsCategories.reduce((dict, category) => {
            if (selectionMode.value == ITEMS_SELECTION_MODE) {
                dict[category] = currentWarehouse.items.filter((item) => (item.category == category))
            }
            else {
                const items = currentWarehouse.items.filter((item) =>
                    (item.category == category) && selectedItems.value.includes(item.id))

                if (items.length > 0) {
                    dict[category] = items
                }
            }

            return dict
        }, {})
    } else if (selectionMode.value == TRANSFER_SELECTION_MODE) {
        return currentWarehouse.items.filter((item) => selectedItems.value.includes(item.id))
    } else {
        return currentWarehouse.items
    }
})
</script>

<template>
    <Loading v-if="loading"></Loading>
    <div v-else>
        <AppBar @back="goBack"
            :title="(selectionMode == ITEMS_SELECTION_MODE) ? 'בחרו פריטים להעביר' : 'בחרו לאן להעביר את הפריטים'">
            <template #default>
                <v-btn v-if="selectionMode == ITEMS_SELECTION_MODE" variant="text" color="#A8372C" @click="selectAll">
                    <div style="font-weight: bolder;">({{ currentWarehouse.items.length }}) סמן הכל</div>
                </v-btn>
            </template>
        </AppBar>

        <div style="margin-bottom: 3.5rem;">
            <v-expansion-panels v-if="categorized" multiple v-model="expansionPanelsState">
                <v-expansion-panel v-for="[category, items] in Object.entries(itemsToShow)" :elevation="0">
                    <v-expansion-panel-title expand-icon="mdi-menu-left" collapse-icon="mdi-menu-down">
                        <span class="font-weight-black" style="position: absolute; right: 50px; color: #857370;">
                            {{ category }}
                        </span>
                        <p
                            style="display: flex; font-size: 0.9rem; font-weight: bold; vertical-align: top; line-height: 1; color: #857370;">
                            {{ items.length }}
                        </p>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text v-for="item in items">
                        <Item :item="item"></Item>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-list v-else>
                <v-list-item v-for="item in itemsToShow" style="padding-inline: 0px !important;">
                    <Item :item="item"></Item>
                    <v-divider style="border-width: 0.8px; color: #857370; margin-block: 1rem;"></v-divider>
                </v-list-item>
            </v-list>
        </div>

        <div v-if="(selectionMode == ITEMS_SELECTION_MODE && selectedItems.length > 0) ||
            (selectionMode == TRANSFER_SELECTION_MODE)">
            <v-btn elevation="0" color="#A8372C" rounded="xl" @click="submit"
                style="z-index: 1; position:fixed; bottom: 20px; left: 20px; font-size: large; height: 50px;">
                <div style="color: white; font-weight: bold;">
                    <span v-if="selectionMode == ITEMS_SELECTION_MODE">
                        העברת {{ selectedItems.length }} פריטים
                    </span>
                    <span v-else>
                        ביצוע העברה
                    </span>
                </div>
            </v-btn>
        </div>

        <v-locale-provider rtl>
            <v-dialog :scrim="false" style="width: 100%; max-width: 700px; max-height: 400px;" v-model="searchModal.isOpen">
                <v-text-field class="custom-text-field" rounded="t-xl"
                    :placeholder="searchModal.currentSelectedWarehouse.name" v-model="searchModal.query">
                    <template #prepend-inner>
                        <v-icon icon="mdi-arrow-right" style="margin-left: 5px;" @click="searchModal.close()"></v-icon>
                    </template>
                    <template #append-inner>
                        <v-icon v-if="searchModal.query.length > 0" icon="mdi-close"
                            @click="searchModal.query = ''"></v-icon>
                    </template>
                    <template #details>
                        <div style="height: 0px; padding: 0px;"></div>
                    </template>
                </v-text-field>
                <v-card rounded="b-xl">
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="warehouse in searchModal.warehousesToShow" rounded="xl" @click="() => {
                                searchModal.currentSelectedWarehouse.name = warehouse.name
                                searchModal.close()
                            }">
                                <template #prepend>
                                    <div style="padding-left: 10px;">
                                        <svg v-if="warehouse.permanent" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20 8.35V19H18V11H6V19H4V8.35L12 5.15L20 8.35ZM22 21V7L12 3L2 7V21H8V13H16V21H22ZM11 19H9V21H11V19ZM13 16H11V18H13V16ZM15 19H13V21H15V19Z"
                                                fill="#534341" />
                                        </svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none">
                                            <path
                                                d="M9.17 6L11.17 8H20V18H4V6H9.17ZM10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z"
                                                fill="#534341" />
                                        </svg>
                                    </div>
                                </template>
                                {{ warehouse.name }}
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-locale-provider>
    </div>
</template>

<style scoped>
.custom-text-field>>>.v-input__details {
    min-height: 0px !important;
    padding: 0px !important;
}

.custom-text-field>>>.v-messages {
    min-height: 0px !important;
}

.custom-text-field {
    background: white;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.v-expansion-panel {
    border-top-style: solid !important;
    border-top-width: thin !important;
    border-color: #857370 !important;
}

.v-expansion-panel:last-child {
    border-bottom-style: solid;
    border-bottom-width: thin;
}

.v-expansion-panel--active:last-child {
    padding-bottom: 16px;
}

.v-expansion-panel::after {
    border-top-style: none;
}

.v-expansion-panel-text>>>.v-expansion-panel-text__wrapper {
    padding: 0 !important;
}

.v-expansion-panel-title>>>.v-expansion-panel-title__icon {
    color: #857370 !important;
}

.v-expansion-panel-title>>>.v-expansion-panel-title__overlay {
    background-color: transparent;
}</style>