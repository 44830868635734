<script setup>
import { removeManualItem, withdrawMode, WITHDRAW_MODE_TRANSFER, permanentWarehouses, itemsData, addManualItem, selectedItems } from './withdraw'
import EquipmentListItem from '../EquipmentListItem.vue';
import { computed, watch, onBeforeMount, ref } from 'vue'
import { availableManualItems } from './withdraw';
import { MANUAL_ITEMS_CATEGORY } from './withdraw';

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    manual: {
        type: Boolean,
        required: true
    }
})

var unselectedSourceWarehouses = undefined
var zeroQuantitySources = undefined
var maxQuantitySources = undefined
var availableSku = undefined
const availableItemsOfType = ref([])
const selectedItemName = ref(props.item.details.item_name)

onBeforeMount(() => {
    if(props.item.details.master_warehouse){
        props.item.state.srcWarehouses[0].name = props.item.details.master_warehouse
    }else if(props.item.details.optional_distributions && props.item.details.abstract_bulk){
        props.item.state.srcWarehouses[0].name = props.item.details.optional_distributions[0].master_warehouse
    }

    if (!props.item.details.checked_out) {
        unselectedSourceWarehouses = computed(() => {
            const names = props.item.state.srcWarehouses.map((w) => w.name)
            console.log(props.item);
            return props.item.details.distribution.filter((w) => !names.includes(w.name))
        })

        zeroQuantitySources = computed(() => {
            /* Indexes of the source warehouses that their selected count is equal to 0 */

            let zeroSources = props.item.state.srcWarehouses.map(({ quantity }, index) => { return { quantity, index } })
            zeroSources = zeroSources.filter(({ quantity }) => quantity === 0)
            zeroSources = zeroSources.map(({ index }) => index)

            return zeroSources
        })

        maxQuantitySources = computed(() => {
            /* Names of the source warehouses that their selected count is equal to (or greater than) their stock */

            let maxSources = props.item.state.srcWarehouses.filter(({ name, quantity }) => {
                const warehouse = props.item.details.distribution.find((w) => w.name == name)
                if (!warehouse) {
                    return false
                }

                return quantity >= warehouse.quantity
            })
            maxSources = maxSources.map(({ name }) => name)

            return maxSources
        })

        availableSku = computed(() => {
            
            if (!props.item.details.optional_distributions || props.manual ) {
                return []
            }
            // let available = props.item.details.optional_distributions.map(({ item_name }) => item_name).flat()
            let available = props.item.details.optional_distributions.map(( item_name ) => item_name.item_name || item_name.name).flat()

            for (const data of Object.values(itemsData.value).flat().filter(({ details }) => details.id != props.item.details.id)) {
                if (data.details.item_type == props.item.details.item_type) {
                    const index = available.findIndex((sku) => data.state.selected && data.state.selected_sku == sku)

                    if (index != -1) {
                        available.splice(index, 1)
                    }
                }
            }
            return available
        })
        
        
        if (props.item.details.individual_tracking) {
            watch(() => props.item.state.selected_sku, (sku) => {
                let warehouse = undefined

                if (props.manual) {
                    warehouse = props.item.details.distribution[0]
                } else {
                    warehouse = props.item.details.distribution.find(({ skus }) => skus?.includes(sku))
                }


                props.item.state.srcWarehouses[0].name = (warehouse) ? warehouse.name : undefined
            }, { immediate: true })
        } else {
            watch(zeroQuantitySources, (zeroSources) => {
                for (const index of zeroSources) {
                    props.item.state.srcWarehouses.splice(index, 1)
                }
            })
        }

        if (props.item.details.abstract_bulk) {
            availableItemsOfType.value = props.item.details.optional_distributions.map((item) => item.item_name)
        }
    }

})


const checkboxEvent = (checked) => {
    if (!checked && props.item.details.individual_tracking) {
        props.item.state.selected_sku = props.item.details.default_sku
    }
}

const addSourceWarehouse = () => {
    props.item.state.srcWarehouses.push({
        name: undefined,
        quantity: 1
    })
}

const setSourceWarehouse = (srcWarehouse) => {
    srcWarehouse.quantity = 1
}

const selectItem = async (item) => {
    const selectedItemName = item.state.selected_sku;
    const distributionsItem = item.details.optional_distributions.filter((el) => el.item_name == selectedItemName)[0];
    item.state.selected_sku_id = distributionsItem.sku
    item.state.destWarehouse = distributionsItem.master_warehouse
    item.state.srcWarehouses = distributionsItem.distribution
}
  

const setItemToAbstractBulk = () => {
    if (selectedItemName.value) {
        const item = props.item.details.optional_distributions.find(({ item_name }) => item_name == selectedItemName.value)
        props.item.details.id = item.id
        props.item.details.distribution = item.distribution
        props.item.state.srcWarehouses = [{
            name: item.distribution[0].name,
            quantity: 1
        }]
    }
}
const title = (item,manual) => {
    if(!manual){
        if((item.details.individual_tracking && item.details.sku)){
            return item.details.item_name
        }else if (item.details.optional_distributions){
            return item.details.item_type
        }else if (!item.details.individual_tracking){
           return item.details.item_name
        }
    }

    return item.details.item_name
    // return ((item.details.individual_tracking || item.details.abstract_bulk) && !manual) ? item.details.item_type : item.details.item_name
}

</script>
<!-- :img-src="item.details.img" -->

<template>
    <EquipmentListItem
        :imgSrc="item.details.img"
        :title="title(item,manual)"
        :quantity="(!item.details.individual_tracking && !item.details.checked_out && !manual) ? item.details.quantity : undefined">
        <template #subcomponents>
            <v-card v-if="!item.details.checked_out" :disabled="!item.state.selected" :elevation="0"
                style="background: transparent;">
                <div v-if="item.details.individual_tracking && item.details.sku">
                    <div v-if="manual">
                        {{ item.details.sku }}
                    </div>
                    <!-- <v-autocomplete v-else density="compact" variant="solo" bg-color="#A8372C0D" flat
                        :placeholder="'בחרו מק״ט'" no-data-text='לא נמצא מק״ט מתאים' :items="availableSku"
                        v-model="item.state.selected_sku" style="width: 14rem; height: 40px;">
                    </v-autocomplete> -->
                    <div style="margin-top: 8px; display: flex;">
                        להעביר מ
                        <span style="font-weight: bolder;">
                            {{ item.state.srcWarehouses[0].name }}
                        </span>
                    </div>
                </div>

                <div v-else-if="item.details.optional_distributions && !item.details.abstract_bulk">
                        <div v-if="manual">
                            {{ item.details.sku }}
                        </div>
                        <!-- <v-autocomplete v-if="props.item.details" rounded="lg" density="compact" variant="solo"
                            bg-color="#A8372C0D" flat :placeholder="'בחרו פריט'" :items="availableItemsOfType"
                            no-data-text="לא נמצאו פריטים" v-model="selectedItemName"
                            style="width: 14rem; height: 40px; margin-bottom: 10px;"
                            @update:model-value="setItemToAbstractBulk">
                        </v-autocomplete> -->
                        
                        
                        <v-autocomplete v-else density="compact" variant="solo" bg-color="#A8372C0D" flat
                            :placeholder="'בחרו מק״ט'" no-data-text='לא נמצא מק״ט מתאים' :items="availableSku"
                            v-model="item.state.selected_sku" @update:model-value="selectItem(item)" style="width: 14rem; height: 40px;">    
                        </v-autocomplete>

                        <!-- <div style="margin-top: 8px; display: flex; background-color: red;">
                            <span style="font-weight: bolder;">
                                לעביר מ- {{item.state.srcWarehouses[0].name}}
                            </span>
                        </div> -->
                        <div v-for="srcWarehouse in item.state.srcWarehouses" style="margin-bottom: 15px;">
                            <!-- <v-autocomplete rounded="lg" density="compact" variant="solo" bg-color="#A8372C0D" flat
                                :placeholder="'בחרו מחסן'"
                                :items="[...((srcWarehouse.name) ? [srcWarehouse.name] : []), ...unselectedSourceWarehouses.map((w) => w.name)]"
                                no-data-text="לא נמצאו מחסנים" v-model="srcWarehouse.name" style="width: 225px; height: 40px;"
                                @update:model-value="setSourceWarehouse(srcWarehouse)"> -->
                                <!-- <template #prepend>
                                    להעביר מ
                                </template> -->
                            <!-- </v-autocomplete> -->
                            <div style="margin-top: 8px; display: flex;">
                                <span style="font-weight: bolder;">
                                    לעביר מ- {{item.state.srcWarehouses[0].name}}
                                </span>
                            </div>
                        </div>
                </div>

                <div v-else>
                    <v-autocomplete v-if="props.item.details.abstract_bulk" rounded="lg" density="compact" variant="solo"
                        bg-color="#A8372C0D" flat :placeholder="'בחרו פריט'" :items="availableItemsOfType"
                        no-data-text="לא נמצאו פריטים" v-model="selectedItemName"
                        style="width: 14rem; height: 40px; margin-bottom: 10px;"
                        @update:model-value="setItemToAbstractBulk">
                    </v-autocomplete>
                    
                    <div v-for="srcWarehouse in item.state.srcWarehouses" style="margin-bottom: 15px;">
                        <v-autocomplete rounded="lg" density="compact" variant="solo" bg-color="#A8372C0D" flat
                            :placeholder="'בחרו מחסן'"
                            :items="[...((srcWarehouse.name) ? [srcWarehouse.name] : []), ...unselectedSourceWarehouses.map((w) => w.name)]"
                            no-data-text="לא נמצאו מחסנים" v-model="srcWarehouse.name" style="width: 225px; height: 40px; "
                            @update:model-value="setSourceWarehouse(srcWarehouse,item)">
                            <template #prepend>
                                להעביר מ
                            </template>
                        </v-autocomplete>

                        <v-chip rounded="lg"
                            style="padding-inline: 0px; background: #A8372C0D; height: 40px; margin-right: 4.8rem; margin-top: 0.5rem;">
                            <v-btn variant="text" elevation="0" rounded="lg" class="quantity-btn"
                                @click="srcWarehouse.quantity--">
                                <v-icon icon="mdi-minus"></v-icon>
                            </v-btn>
                            <v-divider vertical></v-divider>
                            <v-text-field class="quantity-text-field" style="width: fit-content !important;"
                                v-model:model-value="srcWarehouse.quantity" @blur="(_) => {
                                    if (srcWarehouse.quantity == '') {
                                        srcWarehouse.quantity = 0
                                    }
                                    if (srcWarehouse.name) {
                                        // const stockQuantity = props.item.details.distribution.find((w) => w.name == srcWarehouse.name).quantity
                                        // srcWarehouse.quantity = 5
                                    }
                                }"
                                @keypress="(event) => { if (isNaN(Number(event.key))) { event.preventDefault() } }"></v-text-field>

                            <v-divider vertical></v-divider>
                            <v-btn variant="text" elevation="0" rounded="lg" class="quantity-btn"
                                @click="srcWarehouse.quantity++">
                                <v-icon icon="mdi-plus"></v-icon>
                            </v-btn>
                        </v-chip>
                    </div>
                    <v-btn v-if="item.state.srcWarehouses.length < item.details.distribution.length"
                        @click="addSourceWarehouse" flat
                        style="width: 225px; background: #A8372C0D; height: 40px; margin-bottom: 20px; display: block;">
                        <span style="font-size: medium;">
                            + משיכה ממחסן נוסף
                        </span>
                    </v-btn>
                </div>

                <v-autocomplete v-if="withdrawMode === WITHDRAW_MODE_TRANSFER" rounded="lg" density="compact" variant="solo"
                    bg-color="#A8372C0D" flat :placeholder="'בחרו מחסן'"
                    :items="[...((item.state.destWarehouse) ? [item.state.destWarehouse] : []), ...permanentWarehouses.map(({ name }) => name).filter((name) => name != item.state.destWarehouse)]"
                    no-data-text="לא נמצאו מחסנים" v-model="item.state.destWarehouse"
                    style="width: 225px; margin-top: 10px;">
                    <template #prepend>
                        <div style="direction: rtl;">
                            לאחסון ב
                        </div>
                    </template>
                </v-autocomplete>

                <div v-if="!manual && item.details.note" style="color: #534341">
                    {{ item.details.note }}
                </div>
            </v-card>
        </template>
        <template #actions>
            <div v-if="item.details.checked_out" class="checked-out-item-action">
                <div v-if="item.details.individual_tracking">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.79313 15.8754L4.62312 11.7054L3.20312 13.1154L8.79313 18.7054L20.7931 6.70541L19.3831 5.29541L8.79313 15.8754Z"
                            fill="#201A19" />
                    </svg>
                </div>
                <div v-else style="direction: ltr;">
                    {{ item.details.checked_quantity }}/{{ item.details.quantity }}
                </div>
            </div>
            <div v-else>
                <v-checkbox v-if="!manual" class="item-action" color="#A8372C" v-model="item.state.selected"
                    @update:model-value="checkboxEvent">
                </v-checkbox>
                <div v-else>
                    <v-btn @click="removeManualItem(item)" class="item-action" icon="mdi-delete-outline" elevation="0">
                    </v-btn>
                </div>
            </div>
        </template>
    </EquipmentListItem>
</template>

<style scoped>
.v-card>>>.v-card-title {
    line-height: 4rem !important;
    height: 3.5rem !important;
}

.v-autocomplete>>>.v-autocomplete__selection {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.v-chip>>>.v-chip__underlay {
    background: transparent;
}

.item-action {
    margin: 0 !important;
    position: absolute !important;
    top: 45% !important;
    left: 1% !important;
    width: 40px !important;
    height: 40px !important;
}

.checked-out-item-action {
    margin: 0 !important;
    position: absolute !important;
    left: 18px !important;
    top: 33.5%;
}

.quantity-card>>>.v-card-item {
    padding-block: 0px !important;
    padding-right: 0px !important;
}

.quantity-btn {
    padding-inline: 0px;
    min-width: 32px;
}

.quantity-text-field>>>.v-field__input {
    text-align: center !important;
    width: 70px;
}

.quantity-text-field>>>.v-input__details {
    display: none;
}
</style>