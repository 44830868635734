import { ref } from 'vue';
import { getWarehouse } from '@/useAPI';

let currentWarehouse = ref(undefined);
let itemsCategories = undefined;
const expansionPanelsState = ref(undefined);
const loadingWarehouse = ref(true);

const init = async (warehouse_id) => {
  loadingWarehouse.value = true;
  currentWarehouse.value = {
    name: 'טוען...',
  };
  itemsCategories = undefined;
  expansionPanelsState.value = undefined;

  currentWarehouse.value = await getWarehouse(warehouse_id);

  itemsCategories = [
    ...new Set(currentWarehouse.value.items.map((item) => item.category)),
  ];

  expansionPanelsState.value = [...itemsCategories.keys()];
  loadingWarehouse.value = false;
};

export {
  init,
  currentWarehouse,
  itemsCategories,
  expansionPanelsState,
  loadingWarehouse,
};
