<script setup>
import { dateFilter, searchField, activityWarehouses, activitiesStatusOptions } from './home'
import { computed } from 'vue'
import { utils } from '../../utils'
import { warehouse } from '../PersonalActivity/personalActivity';

const activitiesTypes = [...new Set(activityWarehouses.value.map((w) => w.activity.type))]
const warehousesToShow = computed(() => {
    return [...activityWarehouses.value]
        .filter((warehouse) => {
            const activity = warehouse.activity

            const today = new Date() // temporary for development, change to empty constructor in production!
            today.setHours(0)
            today.setMinutes(0)
            today.setSeconds(0)
            today.setMilliseconds(0)

            if (dateFilter.value.currentFilter != dateFilter.value.filterOptions.ALL &&
                activity.status == activitiesStatusOptions.INACTIVE) {
                return false
            }

            switch (dateFilter.value.currentFilter) {
                case dateFilter.value.filterOptions.ALL:
                    return true
                case dateFilter.value.filterOptions.TODAY:
                    return today >= activity.start_date && today <= activity.end_date
                case dateFilter.value.filterOptions.TOMORROW:
                    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)

                    return tomorrow >= activity.start_date && tomorrow <= activity.end_date
                case dateFilter.value.filterOptions.THIS_WEEK:
                    const weekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
                    const weekEnd = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 6)

                    return activity.start_date >= weekStart && activity.start_date <= weekEnd
                case dateFilter.value.filterOptions.THIS_MONTH:
                    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1)
                    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)

                    return activity.start_date >= monthStart && activity.start_date <= monthEnd
            }
        }).filter((warehouse) => {
            if (warehouse.activity.name.toLowerCase().includes(searchField.value.text.toLowerCase())) {
                return true
            }
            else {
                return false
            }
        })
})

const expansionPanelsState = computed(() => {
    return [...new Set(warehousesToShow.value.map((w) => activitiesTypes.findIndex((t) => t == w.activity.type)))]
})
</script>

<template>
    <v-locale-provider rtl>
        <v-slide-group show-arrows direction="horizontal" mandatory>
            <v-slide-group-item v-for="[filterKey, filterName] in Object.entries(dateFilter.filterOptions)">
                <v-btn style="margin-inline: 5px;" class="font-weight-black"
                    :class="(dateFilter.currentFilter == dateFilter.filterOptions[filterKey]) ? 'date-filter-btn-selected' : 'date-filter-btn'"
                    rounded="lg" :elevation="0" @click="dateFilter.setFilter(filterKey)">
                    {{ filterName }}
                </v-btn>
            </v-slide-group-item>
        </v-slide-group>
    </v-locale-provider>

    <v-expansion-panels multiple :model-value="expansionPanelsState">
        <v-expansion-panel v-for="currentPanelType in activitiesTypes" :elevation="0">
            <v-expansion-panel-title expand-icon="mdi-menu-left" collapse-icon="mdi-menu-down">
                <span class="font-weight-black" style="position: absolute; right: 50px; color: #857370;">
                    {{ currentPanelType }}
                </span>
            </v-expansion-panel-title>
            <v-expansion-panel-text v-for="warehouse in warehousesToShow">
                <v-card v-if="warehouse.activity.type == currentPanelType" elevation="0"
                    :class="(warehouse.activity.status == activitiesStatusOptions.INACTIVE) ? 'v-card-inactive' : 'v-card-active'"
                    @click="$router.push({ path: `/manage-activity-warehouse/${warehouse.id}`, query: { redirect: warehouse.items_count == 0 } })">
                    <v-card-item>
                        <template #prepend>
                            <v-chip v-if="warehouse.items_count > 0" variant="outlined"
                                class="equipment-count font-weight-black"
                                :class="(warehouse.activity.status == activitiesStatusOptions.INACTIVE) ? 'equipment-count-inactive' : 'equipment-count-active'">

                                {{ warehouse.items_count }}
                            </v-chip>
                            <svg v-else style="margin-inline: 8px;" width="27" height="27" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M20 6H12L10 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V8C22 6.89 21.11 6 20 6ZM20 18H4V6H9.17L11.17 8H20V18ZM12 14H14V16H16V14H18V12H16V10H14V12H12V14Z"
                                    fill="#251A00" />
                            </svg>
                        </template>

                        <v-card-title>
                            {{ warehouse.activity.name }}
                        </v-card-title>

                        <v-card-subtitle v-if="warehouse.activity.status == activitiesStatusOptions.INACTIVE"
                            style="color: #BA1A1A;">
                            פעילות הסתיימה
                        </v-card-subtitle>
                        <v-card-subtitle v-else style="color: #534341;">
                            <span v-if="warehouse.activity.start_date">{{
                                warehouse.activity.start_date.toLocaleDateString('en-GB') }}</span>
                            <span v-if="warehouse.activity.end_date > warehouse.activity.start_date"> - {{
                                warehouse.activity.end_date.toLocaleDateString('en-GB') }}</span>
                        </v-card-subtitle>

                        <template #append>
                            <v-icon icon="mdi-menu-left" size="30px" style="position: relative;"></v-icon>
                        </template>
                    </v-card-item>
                </v-card>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style scoped>
.v-expansion-panel::after {
    border-top-style: none !important;
}

.v-expansion-panel-text>>>.v-expansion-panel-text__wrapper {
    padding: 0 !important;
}

.v-expansion-panel-title>>>.v-expansion-panel-title__icon {
    color: #857370 !important;
}

.v-expansion-panel-title>>>.v-expansion-panel-title__overlay {
    background-color: transparent;
}

.v-slide-group {
    margin-top: 10px;
}

.date-filter-btn {
    border-width: 1px;
    border-color: #534341;
    color: #534341;
}

.date-filter-btn-selected {
    border-width: 0px;
    color: #30130F;
    background-color: #FFDAD5;
}

.v-card {
    direction: rtl;
    margin-top: 5px;
    padding-block: 12px;
}

.v-card-active {
    background-color: #FBF1F5;
}

.v-card-inactive {
    background-color: #FFFBFF;
}

.equipment-count {
    width: 0px;
    height: 0px;
    padding: 21px;
    justify-content: center;
    border: 1px solid #FFDAD6;
    font-family: Assistant;
    font-size: 19px;
}

.equipment-count-active {
    color: #410001;
    background-color: #FFDAD6;
}

.equipment-count-inactive {
    color: #410002;
    background-color: transparent;
}

.v-card-title {
    line-height: 1.4rem !important;
    white-space: normal;
    font-size: 18px;
    color: #201A19;
}

.v-card-subtitle {
    font-size: 0.875rem;
}
</style>