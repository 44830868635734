<script setup>
import AppBar from '../essentials/AppBar.vue'
import ActivityWarehousesList from './ActivityWarehousesList.vue'
import Loading from '../essentials/Loading.vue';
import {loading, init} from './home'

init()
</script>

<template>
        <AppBar></AppBar>
        <Loading v-if="loading"></Loading>
        <ActivityWarehousesList v-else></ActivityWarehousesList>
</template>