<script setup>
import { sideBarOpen } from './home'
sideBarOpen.value = false
</script>

<template>
    <v-navigation-drawer v-model="sideBarOpen" location="right" width="325" class="side-bar" temporary>
        <v-app-bar-nav-icon style="margin-top: 3%; margin-right: 3.1%;" icon="mdi-close" variant="text"
            @click.stop="sideBarOpen = false"></v-app-bar-nav-icon>

        <v-list nav>
            <v-list-item :to="'/transfer-between-warehouses'">
                <template #prepend>
                    <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.13539 6.31541L0.355391 9.10541C0.165391 9.30541 0.165391 9.61541 0.355391 9.81541L3.13539 12.6054C3.44539 12.9254 3.98539 12.6954 3.98539 12.2554V10.4554H9.99539C10.5454 10.4554 10.9954 10.0054 10.9954 9.45541C10.9954 8.90541 10.5454 8.45541 9.99539 8.45541H3.98539V6.66541C3.98539 6.21541 3.44539 5.99541 3.13539 6.31541ZM17.6454 3.10541L14.8654 0.31541C14.5554 -0.00458974 14.0154 0.22541 14.0154 0.66541V2.45541H7.99539C7.44539 2.45541 6.99539 2.90541 6.99539 3.45541C6.99539 4.00541 7.44539 4.45541 7.99539 4.45541H14.0054V6.24541C14.0054 6.69541 14.5454 6.91541 14.8554 6.59541L17.6354 3.80541C17.8354 3.61541 17.8354 3.29541 17.6454 3.10541Z" />
                    </svg>
                </template>

                <v-list-item-title>
                    העברה בין מחסנים
                </v-list-item-title>
            </v-list-item>

            <v-list-item disabled :to="'/personal-activity'">
                <template #prepend>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.99863 19.7878V14.7878H13.9986V19.7878C13.9986 20.3378 14.4486 20.7878 14.9986 20.7878H17.9986C18.5486 20.7878 18.9986 20.3378 18.9986 19.7878V12.7878H20.6986C21.1586 12.7878 21.3786 12.2178 21.0286 11.9178L12.6686 4.38781C12.2886 4.04781 11.7086 4.04781 11.3286 4.38781L2.96863 11.9178C2.62863 12.2178 2.83863 12.7878 3.29863 12.7878H4.99863V19.7878C4.99863 20.3378 5.44863 20.7878 5.99863 20.7878H8.99863C9.54863 20.7878 9.99863 20.3378 9.99863 19.7878Z"
                            fill="#534341" />
                    </svg>

                </template>

                <v-list-item-title>
                    לקיחת ציוד אישי
                </v-list-item-title>
            </v-list-item>

            <v-list-item disabled :to="'/warehouses-list'">
                <template #prepend>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22 19.3867V8.73668C22 7.91668 21.5 7.18668 20.74 6.87668L12.74 3.67668C12.26 3.48668 11.73 3.48668 11.25 3.67668L3.25 6.87668C2.5 7.18668 2 7.92668 2 8.73668V19.3867C2 20.4867 2.9 21.3867 4 21.3867H7V12.3867H17V21.3867H20C21.1 21.3867 22 20.4867 22 19.3867ZM11 19.3867H9V21.3867H11V19.3867ZM13 16.3867H11V18.3867H13V16.3867ZM15 19.3867H13V21.3867H15V19.3867Z"
                            fill="#534341" />
                    </svg>
                </template>

                <v-list-item-title>
                    רשימות ציוד מחסנים ראשיים
                </v-list-item-title>
            </v-list-item>

            <v-divider style="margin-block: 10px; width: 95%;"></v-divider>

            <v-list-item disabled :to="'/login'">
                <template #prepend>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17 8.46045L15.59 9.87045L17.17 11.4604H9V13.4604H17.17L15.59 15.0404L17 16.4604L21 12.4604L17 8.46045ZM5 5.46045H12V3.46045H5C3.9 3.46045 3 4.36045 3 5.46045V19.4604C3 20.5604 3.9 21.4604 5 21.4604H12V19.4604H5V5.46045Z"
                            fill="#534341" />
                    </svg>
                </template>

                <v-list-item-title>
                    התנתק / החלף משתמש
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<style scoped>
.v-list-item {
    border-radius: 100px;
    color: #534341;
    fill: #534341;
    height: 56px;
    padding-inline: 18px;
}

.v-list-item-title {
    font-size: 18px;
    margin-right: 15px;
    font-weight: bold;
    align-items: center;
}

.v-navigation-drawer {
    background: linear-gradient(0deg, rgba(168, 55, 44, 0.05), rgba(168, 55, 44, 0.05)), #FFFBFF;
    border-radius: 16px 0px 0px 16px;
    direction: rtl;
}
</style>