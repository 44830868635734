<script setup>
import TransferTemplate from '../../essentials/TransferTemplate/TransferTemplate.vue'

const warehouse = {
    "items": [
        {
            "id": 1459,
            "sku": "123456",
            "name": "Tripod-advance-arizona",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "בטיחות"
        },
        {
            "id": 80088,
            "sku": "23323",
            "name": "Carabiner-twist-lock",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": false,
            "quantity": 10,
            "category": "ציוד עבודה"
        },
        {
            "id": 567891,
            "sku": "9224765",
            "name": "Tripod-Advance-carolina",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "ציוד עבודה"
        }
    ]
}
</script>

<template>
    <TransferTemplate :warehouse="warehouse" :categorized="false" :backRoute="'/personal-activity'"></TransferTemplate>
</template>