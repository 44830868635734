<script setup>
import {expansionPanelsState, itemsCategories, currentWarehouse} from './manageActivity'
import EquipmentListItem from '../essentials/EquipmentListItem.vue'
import Report from '../essentials/Report.vue'
import {ref} from 'vue'

const reportRef = ref(null)
</script>

<template>
    <v-expansion-panels multiple style="margin-top: 3.5rem;" v-model="expansionPanelsState">
        <v-expansion-panel v-for="currentPanelCategory in itemsCategories" :elevation="0">
            <v-expansion-panel-title expand-icon="mdi-menu-left" collapse-icon="mdi-menu-down">
                <span class="font-weight-black" style="position: absolute; right: 50px; color: #857370;">
                    {{ currentPanelCategory }}
                </span>
            </v-expansion-panel-title>
            <v-expansion-panel-text v-for="item in currentWarehouse.items">
                <EquipmentListItem v-if="item.category == currentPanelCategory" :title="item.name" :img-src="item.img">
                    <template #subcomponents>
                        <v-card-subtitle style="padding-right: 0px;">
                            <p v-if="item.individual_tracking">
                                {{ item.sku }}
                                <br>
                            </p>
                            <p v-else>
                                {{ item.quantity }} יח׳
                            </p>
                        </v-card-subtitle>
                    </template>
                    <template #actions>
                        <v-menu>
                            <template #activator="{ props }">
                                <v-btn style="position: absolute; left: 3px;" elevation="0" icon="mdi-dots-vertical"
                                    v-bind="props"></v-btn>
                            </template>
                            <v-list
                                style="background: linear-gradient(0deg, rgba(168, 55, 44, 0.08), rgba(168, 55, 44, 0.08)), #FFFBFF;">
                                <v-list-item @click="reportRef.open(reportRef.REPORT_TYPE_DAMAGED, item.id)">
                                    <v-list-item-title>
                                        דיווח על תקלה בפריט
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="reportRef.open(reportRef.REPORT_TYPE_LOST, item.id)">
                                    <v-list-item-title>
                                        דיווח על אובדן פריט
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </EquipmentListItem>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>

    <Report ref="reportRef"></Report>
</template>

<style scoped>
.v-expansion-panel {
    border-top-style: solid !important;
    border-top-width: thin !important;
    border-color: #857370 !important;
}

.v-expansion-panel:last-child {
    border-bottom-style: solid !important;
    border-bottom-width: thin !important;
}

.v-expansion-panel--active:last-child {
    padding-bottom: 16px !important;
}

.v-expansion-panel::after {
    border-top-style: none;
}

.v-expansion-panel-text>>>.v-expansion-panel-text__wrapper {
    padding: 0 !important;
}

.v-expansion-panel-title>>>.v-expansion-panel-title__icon {
    color: #857370 !important;
}

.v-expansion-panel-title>>>.v-expansion-panel-title__overlay {
    background-color: transparent;
}

.v-card>>>.v-card-item {
    padding-left: 0px !important;
}

.v-list>>>.v-list-item__content {
    display: flex !important;
    direction: rtl;
}
</style>