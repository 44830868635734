<script setup>
import { currentWarehouse, itemsCategories, expansionPanelsState, init, loadingWarehouse } from './manageActivity'
import AppBar from '../essentials/AppBar.vue'
import ItemsList from './ItemsList.vue'
import Loading from '@/components/essentials/Loading.vue'
import { useRoute, useRouter } from 'vue-router'
import { watch } from 'vue'

const route = useRoute()
const router = useRouter()

const warehouse_id = route.params.id
init(warehouse_id)

watch(loadingWarehouse, (loading) => {
    if (!loading && currentWarehouse.value.items.length == 0 && route.query.redirect == 'true') {
        router.push(`/withdraw-from-warehouse/${warehouse_id}`)
    }
})
</script>

<template>
    <Loading v-if="loadingWarehouse"></Loading>
    <div v-else-if="$route.query.redirect != 'true'">
        <AppBar @back="$router.push('/')" :title="currentWarehouse.name">
            <v-menu>
                <template #activator="{ props }">
                    <v-btn icon="mdi-dots-horizontal" v-bind="props"></v-btn>
                </template>

                <v-list
                    style="background: linear-gradient(0deg, rgba(168, 55, 44, 0.08), rgba(168, 55, 44, 0.08)), #FFFBFF;">
                    <v-list-item @click="">
                        <v-icon icon="mdi-list-status" style="margin-left: 15px; float: right;"></v-icon>
                        <v-list-item-title>
                            בדיקת ציוד יומית
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </AppBar>
        <p dir="rtl" style="padding: 20px; font-size: medium; color: #201A19;">
            הערות שרשמו בעת הכנת הפעילות כדי שהראש צוות יראה
        </p>
        <v-container class="main-btns-container">
            <v-btn :disabled="loadingWarehouse" :to="`/withdraw-from-warehouse/${warehouse_id}`" class="main-btns"
                style="float: right;" elevation="0">
                <template #prepend>
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15 4.57178H9L7.5 3.07178H3C2.1675 3.07178 1.5075 3.73928 1.5075 4.57178L1.5 13.5718C1.5 14.4043 2.1675 15.0718 3 15.0718H15C15.8325 15.0718 16.5 14.4043 16.5 13.5718V6.07178C16.5 5.23928 15.8325 4.57178 15 4.57178ZM15 13.5718H3V4.57178H6.8775L8.3775 6.07178H15V13.5718ZM9 10.5718H10.5V12.0718H12V10.5718H13.5V9.07178H12V7.57178H10.5V9.07178H9V10.5718Z"
                            fill="white" />
                    </svg>
                </template>
                משיכה ממחסן
            </v-btn>

            <v-btn :disabled="loadingWarehouse" :to="`/transfer-to-warehouse/${warehouse_id}`" class="main-btns"
                style="float: left;" elevation="0">
                <template #prepend>
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15 4.57178H9L7.5 3.07178H3C2.175 3.07178 1.5 3.74678 1.5 4.57178V13.5718C1.5 14.3968 2.175 15.0718 3 15.0718H15C15.825 15.0718 16.5 14.3968 16.5 13.5718V6.07178C16.5 5.24678 15.825 4.57178 15 4.57178ZM15 13.5718H3V4.57178H6.8775L8.3775 6.07178H15V13.5718ZM10.0575 11.7643L9 12.8218L6 9.82178L9 6.82178L10.0575 7.87928L8.8725 9.07178H12V10.5718H8.8725L10.0575 11.7643Z"
                            fill="white" />
                    </svg>
                </template>
                החזרה / העברה
            </v-btn>
        </v-container>


        <ItemsList></ItemsList>
    </div>
</template>

<style scoped>
.main-btns {
    border-radius: 100px;
    background-color: #A8372C;
    color: white;
    font-weight: 700;
    height: 2.75rem;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    letter-spacing: normal;
    width: 47%;
}

.main-btns-container {
    direction: rtl;
    margin-top: 5px;
    width: 100%;
}
</style>