<script setup>
import { isAddingManualItem, addManualItem, MANUAL_ITEMS_CATEGORY, itemsData, availableManualItems, selectedItems } from './withdraw'
import { computed } from 'vue';
import EquipmentSearch from '../../essentials/EquipmentSearch.vue';

const add = (item) => {
    addManualItem(item)
    isAddingManualItem.value = false
}
</script>

<template>
    <EquipmentSearch :available-items="availableManualItems" :selected-items="selectedItems"
        @back="isAddingManualItem = false" @onSelect="add">
    </EquipmentSearch>
</template>