async function fetchData(url, queryParams) {
  try {
    let response = await fetch(url + '?' + new URLSearchParams(queryParams));

    let threshold = 5; // May need to fine-tune in the future in accordance to fail rate and UX
    if (!response.ok) return;

    while (!response.ok && threshold--) {
      await new Promise((resolve) => setTimeout(1000, () => resolve())); // Wait a second after failure
      response = await fetch(url + '?' + new URLSearchParams(queryParams));
    }

    const jsonRes = await response.json();
    const data = jsonRes['data'];

    return data;
  } catch (e) {
    console.log(e);
  }
}

async function getWarehouses() {
  const allData = await fetchData(
    'https://getwarehouses-komqcochfq-uc.a.run.app'
  );
  const data = allData.filter((warehouse) => warehouse.Status === 'Active');

  const toDate = (dateStr) => {
    if (!dateStr) {
      return undefined;
    }

    const parts = dateStr.split('/');
    const date = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`);

    return date;
  };

  const warehouses = data.map((w) => {
    return {
      name: w['Name'],
      id: w['ID'],
      items_count:
        Number(w['Individual_Items_Count']) + Number(w['Bulk_Items_Count']),
      permanent: w['Permanent'] == 'true',
      activity: {
        id: w['ID'],
        name: w['Activity.title'],
        start_date: toDate(w['Activity.start_date']),
        end_date: toDate(w['Activity.end_date']),
        status: ['מבוטלת', 'הסתיימה', 'סגורה'].includes(w['Activity.status'])
          ? 'פעילות הסתיימה'
          : w['Activity.status'],
        type: w['Activity.type'],
      },
    };
  });

  return warehouses;
}

async function getWarehouse(warehouse_id) {
  try {
    const warehouse = await fetchData(
      'https://getwarehouse-komqcochfq-uc.a.run.app',
      { id: warehouse_id }
    );
    return warehouse;
  } catch (err) {}
}

async function getWarehouseItems(warehouse_id) {
  const data = await fetchData('https://getitems-komqcochfq-uc.a.run.app', {
    warehouse_id,
  });

  return data;
}

async function getItemBySKU(sku) {
  const data = await fetchData('https://getitems-komqcochfq-uc.a.run.app', {
    sku,
  });

  return data[0];
}

async function getAvailableManualItems() {
  try {
    const availableManualItems = await fetchData(
      'https://getavailablemanualitems-komqcochfq-uc.a.run.app'
    );

    return availableManualItems;
  } catch (err) {}
}

async function getPermanentWarehouses() {
  const data = await fetchData(
    'https://getwarehouses-komqcochfq-uc.a.run.app',
    { permanent: true }
  );
  const permanentWarehouses = data.map((w) => {
    return {
      id: w['ID'],
      name: w['Name'],
    };
  });

  return permanentWarehouses;
}

async function postTransactions(transactions) {
  const response = await fetch(
    'https://posttransactions-komqcochfq-uc.a.run.app',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(transactions),
    }
  );

  return response.ok;
}

async function postReport(report) {
  const response = await fetch('https://postreport-komqcochfq-uc.a.run.app', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(report),
  });

  return response.ok;
}

export {
  getWarehouses,
  getWarehouse,
  getItemBySKU,
  getAvailableManualItems,
  getPermanentWarehouses,
  postTransactions,
  postReport,
};
