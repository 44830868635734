import {createRouter, createWebHistory} from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home/Home.vue'
import TransferBetweenWarehouses from '../components/TransferBetweenWarehouses/TransferBetweenWarehouses.vue'
import PersonalActivity from '../components/PersonalActivity/PersonalActivity.vue'
import PersonalActivityWithdraw from '../components/PersonalActivity/Withdraw/Withdraw.vue'
import PersonalActivityTransfer from '../components/PersonalActivity/Transfer/Transfer.vue'
import WarehousesList from '../components/WarehousesList/WarehousesList.vue'
import ManageActivityWarehouse from '../components/ManageActivityWarehouse/ManageActivityWarehouse.vue'
import WithdrawFromWarehouse from '../components/WithdrawFromWarehouse/WithdrawFromWarehouse.vue'
import TransferToWarehouse from '../components/TransferToWarehouse/TransferToWarehouse.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/transfer-between-warehouses',
            name: 'transfer between warehouses',
            component: TransferBetweenWarehouses
        },
        {
            path: '/personal-activity',
            name: 'personal activity',
            component: PersonalActivity
        },
        {
            path: '/personal-activity/withdraw',
            name: 'personal activity - withdraw',
            component: PersonalActivityWithdraw
        },
        {
            path: '/personal-activity/transfer',
            name: 'personal activity - transfer',
            component: PersonalActivityTransfer
        },
        {
            path: '/warehouses-list',
            name: 'warehouses list',
            component: WarehousesList
        },
        {
            path: '/manage-activity-warehouse/:id',
            name: 'manage activity warehouse',
            component: ManageActivityWarehouse
        },
        {
            path: '/withdraw-from-warehouse/:id',
            name: 'withdraw from warehouse',
            component: WithdrawFromWarehouse
        },
        {
            path: '/transfer-to-warehouse/:id',
            name: 'transfer to warehouse',
            component: TransferToWarehouse
        }
    ]
});

export default router;