import { ref } from 'vue';
import { getWarehouses } from '../../useAPI';

const activitiesStatusOptions = {
  PREACTIVE: 'לפני ביצוע',
  ACTIVE: 'פעילות בביצוע',
  INACTIVE: 'פעילות הסתיימה',
};

const sideBarOpen = ref(false);
const dateFilter = ref(undefined);
const searchField = ref(undefined);
const activityWarehouses = ref(undefined);
const loading = ref(true);

const init = async () => {
  loading.value = true;
  activityWarehouses.value = {};
  sideBarOpen.value = false;
  dateFilter.value = {
    filterOptions: {
      ALL: 'כל הפעילויות',
      TODAY: 'היום',
      TOMORROW: 'מחר',
      THIS_WEEK: 'השבוע',
      THIS_MONTH: 'החודש',
    },
    currentFilter: 'כל הפעילויות',
    setFilter(filterKey) {
      this.currentFilter = this.filterOptions[filterKey];
    },
  };
  searchField.value = {
    text: '',
    isOpen: false,
    setOpen(state) {
      this.isOpen = state;
      if (!state) this.text = '';
    },
  };

  activityWarehouses.value = (await getWarehouses()).filter(
    (w) => w.activity.name
  );
  loading.value = false;
};

export {
  init,
  sideBarOpen,
  dateFilter,
  searchField,
  activitiesStatusOptions,
  activityWarehouses,
  loading,
};
