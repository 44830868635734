const warehouses = [{
    "name": "מחסן הדרכות ראשי",
    "id": 4512954,
    "note": 'הערות של המחסן, נגיד הכוונה לסוג ציוד או דרך לסדר אותו',
    "items": [
        {
            "id": 99228127,
            "sku": "123456",
            "name": "Tripod-advance-arizona",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "בטיחות"
        },
        {
            "id": 99228127,
            "sku": "68195543",
            "name": "Tripod-advance-arizona",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "בטיחות"
        },
        {
            "id": 99228127,
            "sku": "447221",
            "name": "Tripod-advance-arizona",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "בטיחות"
        },
        {
            "id": 767687687,
            "sku": "23323",
            "name": "Carabiner-twist-lock",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": false,
            "quantity": 10,
            "category": "ציוד עבודה"
        }
    ],
},
{
    "name": "מחסן ראשי פרוייקטים",
    "id": 113355,
    "note": 'הערות של המחסן, נגיד הכוונה לסוג ציוד או דרך לסדר אותו',
    "items": [
        {
            "id": 767687687,
            "sku": "23323",
            "name": "Carabiner-twist-lock",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": false,
            "quantity": 20,
            "category": "ציוד עבודה"
        },
        {
            "id": 99228127,
            "sku": "989878",
            "name": "Tripod-Advance-Carolina",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "בטיחות"
        },
        {
            "id": 99228127,
            "sku": "3213214",
            "name": "Tripod-Advance-Carolina",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "בטיחות"
        },
        {
            "id": 99228127,
            "sku": "7658675756",
            "name": "Tripod-advance-arizona",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "בטיחות"
        }
    ],
}]

export {
    warehouses
}