<script setup>
import TransferTemplate from '../essentials/TransferTemplate/TransferTemplate.vue'
import {currentWarehouse} from '@/components/ManageActivityWarehouse/manageActivity'
import { useRouter } from 'vue-router';

if(!currentWarehouse.value) {
    useRouter().push('/')
}
</script>

<template>
    <TransferTemplate v-if="currentWarehouse" :warehouse="currentWarehouse" :backRoute="`/manage-activity-warehouse/${currentWarehouse.id}`" categorized></TransferTemplate>
</template>

<style scoped>
</style>