<script setup>
import WithdrawTemplate from '../essentials/WithdrawTemplate/WithdrawTemplate.vue'
import {WITHDRAW_MODE_REGULAR} from '../essentials/WithdrawTemplate/withdraw'
import { currentWarehouse } from '../ManageActivityWarehouse/manageActivity';
import { useRouter, useRoute } from 'vue-router';
import Loading from '../essentials/Loading.vue';
import { watch } from 'vue';

const router = useRouter()
const route = useRoute()
let backRoute = `/manage-activity-warehouse/${route.params.id}`

if(!currentWarehouse.value) {
    router.push(backRoute)
}
</script>

<template>
    <WithdrawTemplate v-if="currentWarehouse" :warehouse="currentWarehouse" :backRoute="backRoute" :withdraw_mode="WITHDRAW_MODE_REGULAR">
    </WithdrawTemplate>
</template>