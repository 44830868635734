<script setup>

</script>

<template>
    <div
        style="display: block; margin: auto; margin-top: 10rem; width: fit-content; height: fit-content; vertical-align:middle;">
        <v-progress-circular indeterminate :size="50" :width="5" color="#A8372C" bg-color="white">
        </v-progress-circular>
    </div>
</template>