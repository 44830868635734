import {ref, computed, watch} from 'vue'
import {successSnackbar, errorSnackbar} from '../../../app'
import {getWarehouses, postTransactions} from '@/useAPI'

const DO_NOT_TRANSFER = 'לא להעביר'
const ITEMS_SELECTION_MODE = 0
const TRANSFER_SELECTION_MODE = 1

let currentWarehouse = undefined
let isCategorized = undefined
let itemsCategories = undefined
let availableWarehouses = undefined
const selectionMode = ref(undefined)
const selectedItems = ref(undefined)
const transferSelectionState = ref(undefined)
const expansionPanelsState = ref(undefined)
const loading = ref(false)

const searchModal = ref({
    isOpen: false,
    query: '',
    currentItemID: undefined,
    currentSelectedWarehouse: undefined,
    open(itemID, selectedWarehouses) {
        this.currentItemID = itemID
        this.currentSelectedWarehouse = selectedWarehouses
        this.query = ''
        this.isOpen = true
    },
    close() {
        this.isOpen = false
    },
    warehousesToShow: computed(() => {
        return availableWarehouses.filter((warehouse) => {
            /* Filter out selected warehouses */
            const itemState = transferSelectionState.value[searchModal.value.currentItemID]
            return !itemState.selectedWarehouses.map((w) => w.name).includes(warehouse.name)
        }).filter((warehouse) => warehouse.name.toLowerCase().includes(searchModal.value.query.toLowerCase()))
    })
})

const unwatchers = []

const init = async (warehouse, categorized) => {
    loading.value = true
    availableWarehouses = (await getWarehouses()).filter((w) => w.name)
    loading.value = false
    currentWarehouse = warehouse
    isCategorized = categorized
    selectionMode.value = ITEMS_SELECTION_MODE
    selectedItems.value = []
    transferSelectionState.value = {}

    if(categorized) {
        itemsCategories = [...new Set(currentWarehouse.items.map((item) => item.category))]
        expansionPanelsState.value = [...itemsCategories.keys()]
    }

    while(unwatchers.length) {
        const unwatcher = unwatchers.pop()
        unwatcher()
    }
}


const goTransferSelectionMode = () => {
    for (const id of selectedItems.value) {
        const item = currentWarehouse.items.find((t) => t.id == id)
        transferSelectionState.value[id] = {
            selectedWarehouses: [{ 
                name: item.master_warehouse, 
                quantity: (item.individual_tracking) ? 1 : item.quantity,
            }]
        }

        if(!item.individual_tracking) {
            transferSelectionState.value[id].quantitySum = computed(() => {
                let sum = 0
                for(const warehouse of transferSelectionState.value[id].selectedWarehouses) {
                    if(warehouse.name != DO_NOT_TRANSFER) {
                        sum += warehouse.quantity
                    }
                }

                return sum
            })

            const unwatcher = watch(() => transferSelectionState.value[id].quantitySum, (quantitySum) => {
                const doNotTransferIndex = 
                    transferSelectionState.value[id].selectedWarehouses.findIndex((warehouse) => warehouse.name == DO_NOT_TRANSFER)
                
                if(doNotTransferIndex > -1) {
                    transferSelectionState.value[id].selectedWarehouses[doNotTransferIndex].quantity = item.quantity - quantitySum
                }
                else if(quantitySum < item.quantity) {
                    transferSelectionState.value[id].selectedWarehouses.push({ 
                        name: DO_NOT_TRANSFER, 
                        quantity: item.quantity - quantitySum
                    })
                }

                transferSelectionState.value[id].selectedWarehouses = 
                    transferSelectionState.value[id].selectedWarehouses.filter((warehouse) => warehouse.quantity > 0)
            })

            unwatchers.push(unwatcher)
        }
    }

    if(isCategorized) {
        expansionPanelsState.value = [...itemsCategories.keys()]
    }
    
    selectionMode.value = TRANSFER_SELECTION_MODE
}

const exitTransferSelectionMode = () => {
    transferSelectionState.value = {}
    for(const unwatcher of unwatchers) {
        unwatcher()
    }

    if(isCategorized) {
        expansionPanelsState.value = [...itemsCategories.keys()]
    }
    
    selectionMode.value = ITEMS_SELECTION_MODE
}

const postItems = async () => {
    const transactions = []

    for(const [itemID, state] of Object.entries(transferSelectionState.value)) {
        const item = currentWarehouse.items.find(({id}) => id == itemID)

        for(const warehouse of state.selectedWarehouses) {
            if(warehouse.name == DO_NOT_TRANSFER) {
                continue
            }

            const transaction = {
                Warehouse_Out: currentWarehouse.id,
                Warehouse_In: availableWarehouses.find((w) => w.name == warehouse.name).id,
                Item: itemID,
                Item_Count: warehouse.quantity
            }

            transactions.push(transaction)
        }
    }

    const success = await postTransactions(transactions)

    if(success) {
        successSnackbar.value.open('הציוד שלך הועבר בהצלחה')
    } else {
        errorSnackbar.value.open('שגיאה בהעברה')
    }
}

export {
    init,
    currentWarehouse,
    availableWarehouses,
    selectionMode,
    itemsCategories,
    selectedItems,
    transferSelectionState,
    searchModal,
    expansionPanelsState,
    goTransferSelectionMode,
    exitTransferSelectionMode,
    postItems,
    DO_NOT_TRANSFER,
    ITEMS_SELECTION_MODE,
    TRANSFER_SELECTION_MODE,
    loading
}
