import { ref, computed, watch } from 'vue';
import { successSnackbar, errorSnackbar } from '../../../app';
import {
  getItemBySKU,
  getAvailableManualItems,
  getPermanentWarehouses,
  postTransactions,
} from '@/useAPI';

const WITHDRAW_MODE_REGULAR = 0;
const WITHDRAW_MODE_PERSONAL = 1;
const WITHDRAW_MODE_TRANSFER = 2;

const MANUAL_ITEMS_CATEGORY = 'פריטים מחוץ לרשימה';

let currentWarehouse = undefined;
let takeoutItemsCategories = undefined;
let withdrawMode = undefined;
let availableManualItems = undefined;
let permanentWarehouses = undefined;
const itemsData = ref(undefined);
const isAddingManualItem = ref(undefined);
const loading = ref(false);
const submissionDialog = ref(undefined);
const expansionPanelsState = ref(undefined);

const init = async (warehouse, wm) => {
  currentWarehouse = warehouse;
  withdrawMode = wm || WITHDRAW_MODE_REGULAR;
  itemsData.value = {};
  isAddingManualItem.value = false;
  availableManualItems = undefined;
  permanentWarehouses = undefined;

  loading.value = true;
  availableManualItems = await getAvailableManualItems();
  permanentWarehouses = await getPermanentWarehouses();
  loading.value = false;

  // Chnage taken distribution Item to checked out object
  warehouse.items.map((item) => {
    const find = warehouse.takeout_list.find(
      (takeout) => takeout.id === item.id
    );

    if (!find) {
      warehouse.takeout_list.map((el, i) => {
        if (el.optional_distributions) {
          el.optional_distributions.map((distributionItem) => {
            if (distributionItem?.id == item.id) {
              item.checked_out = true;
              item.checked_quantity = 1;
              item.item_category = item.category;
              item.item_name = item.name;
              item.note = '';
              item.sku = distributionItem.id;

              warehouse.takeout_list[i] = item;
            }
          });
        }
      });
    }
  });

  if (withdrawMode === WITHDRAW_MODE_REGULAR && warehouse.takeout_list) {
    takeoutItemsCategories = warehouse.takeout_list
      ? [...new Set(warehouse.takeout_list.map((item) => item.item_category))]
      : [];
    expansionPanelsState.value = [...takeoutItemsCategories.keys()];
    submissionDialog.value = false;

    for (const category of takeoutItemsCategories) {
      itemsData.value[category] = [];
      for (const item of warehouse.takeout_list.filter(
        (item) => item.item_category == category
      )) {
        const data = {
          details: item,
          state: {
            selected: item.checked_out || item.default_sku != undefined,
            ...(item.individual_tracking && { selected_sku: item.default_sku }),
            srcWarehouses: [
              {
                name: undefined,
                quantity: 1,
              },
            ],
            destWarehouse: undefined,
          },
        };
        itemsData.value[category].push(data);
      }
    }
  }
};

const takeoutSelectedCount = computed(() => {
  let count = 0;
  for (const [category, items] of Object.entries(itemsData.value)) {
    if (category != MANUAL_ITEMS_CATEGORY) {
      count += items.filter((item) => item.state.selected).length;
    }
  }

  return count;
});

const selectedCountPerCategory = computed(() => {
  const counterDict = {};

  for (const [category, items] of Object.entries(itemsData.value)) {
    counterDict[category] = items.filter((item) => item.state.selected).length;
  }

  return counterDict;
});

const selectedItems = computed(() => {
  return Object.values(itemsData.value)
    .flat()
    .filter(
      ({ details }) => !(!details.individual_tracking && details.checked_out)
    )
    .map(({ details }) => details.id);
});

const addManualItem = (item) => {
  const manualItem = {
    details: { ...item, item_category: MANUAL_ITEMS_CATEGORY },
    state: {
      selected: true,
      ...(item.individual_tracking && { selected_sku: item.sku }),
      srcWarehouses: [
        {
          name:
            item.distribution.length == 1
              ? item.distribution[0].name
              : undefined,
          quantity: 1,
        },
      ],
      destWarehouse: item.master_warehouse,
    },
  };

  if (MANUAL_ITEMS_CATEGORY in itemsData.value) {
    itemsData.value[MANUAL_ITEMS_CATEGORY].push(manualItem);
  } else {
    itemsData.value[MANUAL_ITEMS_CATEGORY] = [manualItem];
  }

  if (
    withdrawMode === WITHDRAW_MODE_REGULAR &&
    currentWarehouse.takeout_list &&
    !expansionPanelsState.value.includes(takeoutItemsCategories.length)
  ) {
    expansionPanelsState.value.push(takeoutItemsCategories.length);
  }
};

const removeManualItem = (item) => {
  const itemIndex = itemsData.value[MANUAL_ITEMS_CATEGORY].findIndex(
    ({ details }) => details.id == item.details.id
  );
  itemsData.value[MANUAL_ITEMS_CATEGORY].splice(itemIndex, 1);

  if (itemsData.value[MANUAL_ITEMS_CATEGORY].length == 0) {
    delete itemsData.value[MANUAL_ITEMS_CATEGORY];

    if (
      withdrawMode === WITHDRAW_MODE_REGULAR &&
      currentWarehouse.takeout_list
    ) {
      expansionPanelsState.value.pop();
    }
  }
};

const postItems = async () => {
  const transactions = [];
  for (const item of Object.values(itemsData.value).flat()) {
    if (item.state.selected && !item.details.checked_out) {
      for (const source of item.state.srcWarehouses) {
        const transaction = {
          Warehouse_Out: item.details.distribution.find(
            (w) => w.name == source.name
          ).id,
          Warehouse_In:
            withdrawMode === WITHDRAW_MODE_TRANSFER
              ? permanentWarehouses.find(
                  ({ name }) => name == item.state.destWarehouse
                ).id
              : currentWarehouse.id,
        };

        if (item.details.individual_tracking) {
          transaction.Item = (await getItemBySKU(item.state.selected_sku))[
            'ID'
          ];
          transaction.Item_Count = 1;
        } else if (item.state.selected_sku_id) {
          transaction.Item = item.state.selected_sku_id;
          transaction.Item_Count = 1;
        } else {
          transaction.Item = item.details.id;
          transaction.Item_Count = source.quantity;
        }
        transactions.push(transaction);
      }
    }
  }

  const success = await postTransactions(transactions);

  if (success) {
    successSnackbar.value.open(
      withdrawMode != WITHDRAW_MODE_TRANSFER
        ? 'הציוד נוסף אל הפעילות שלך בהצלחה'
        : 'הציוד הועבר בהצלחה'
    );
  } else {
    errorSnackbar.value.open('שגיאה בהעברה');
  }
};

export {
  init,
  currentWarehouse,
  takeoutItemsCategories,
  withdrawMode,
  itemsData,
  takeoutSelectedCount,
  selectedCountPerCategory,
  selectedItems,
  addManualItem,
  removeManualItem,
  postItems,
  WITHDRAW_MODE_REGULAR,
  WITHDRAW_MODE_PERSONAL,
  WITHDRAW_MODE_TRANSFER,
  MANUAL_ITEMS_CATEGORY,
  expansionPanelsState,
  submissionDialog,
  isAddingManualItem,
  permanentWarehouses,
  availableManualItems,
  loading,
};
