<script setup>
import WithdrawTemplate from '../../essentials/WithdrawTemplate/WithdrawTemplate.vue';
import {WITHDRAW_MODE_PERSONAL} from '../../essentials/WithdrawTemplate/withdraw'

const warehouse = {
    "name": "התקנת קווי חיים - אינטגל גבעתיים",
    "items_count": 11,
    "activity": {
        "id": 4241804000000220003,
        "name": "התקנת קווי חיים - אינטגל גבעתיים",
        "start_date": "01/08/2023",
        "end_date": "01/08/2023",
        "status": "פעילות הסתיימה",
        "type": "ביצוע"
    },
    "id": 42418040000002200087,
    "items": [
        {
            "id": 4241804000000220003,
            "sku": "123456",
            "name": "Tripod-advance-arizona",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "category": "בטיחות"
        },
        {
            "id": 42418040000002200044,
            "sku": "23323",
            "name": "Carabiner-twist-lock",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": false,
            "quantity": 10,
            "category": "ציוד עבודה"
        }
    ],
    "takeout_list": [
        {
            "id": 42418,
            "item_type": "Tripod-advance-arizona",
            "item_category": "בטיחות",
            "default_sku": 1027838282,
            "available_sku": [120983, 123098123, 123098012, 12098123],
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": true,
            "quantity": 1,
            "checked_out": false,
            note: "נמצא אצל נדב במשרד"
        },
        {
            "id": 42417,
            "item_type": "רתמות טיפוס תפס עבה",
            "item_category": "בטיחות",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": false,
            "quantity": 20,
            "checked_out": false
        },
        {
            "id": 42419,
            "item_type": "10 תחבושות אישיות",
            "item_category": "רפואה",
            "img": "https://www.adorama.com/images/Large/advatgta.jpg",
            "individual_tracking": false,
            "quantity": 1,
            "checked_out": true
        }
    ]
}
</script>

<template>
    <WithdrawTemplate :warehouse="warehouse" back-route="/personal-activity" :withdraw_mode="WITHDRAW_MODE_PERSONAL"></WithdrawTemplate>
</template>

<style scoped>
</style>