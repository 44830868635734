<script setup>
import { ref, computed } from 'vue';
import EquipmentListItem from './EquipmentListItem.vue';

const props = defineProps({
    availableItems: {
        type: Object,
        required: true
    },
    selectedItems: {
        type: Object,
        required: true
    }
})

const emits = defineEmits(['onSelect', 'back'])

const searchField = ref("")
const itemsToShow = computed(() => {
    if (searchField.value.length < 1) {
        return []
    }
    else {
        return props.availableItems.filter((item) => {
            return !props.selectedItems.includes(item.id) &&
                (item.item_name.toLowerCase().includes(searchField.value.toLowerCase()) ||
                    (item.individual_tracking && item.sku.toString().includes(searchField.value)))
        })
    }
})
</script>

<template>
    <v-text-field placeholder="חפש לפי שם פריט או מק״ט" append-inner-icon="mdi-arrow-right"
        @click:append-inner="$emit('back')" :prepend-inner-icon="searchField.length > 0 ? 'mdi-close' : ''"
        @click:prepend-inner="searchField = ''" dir="rtl" v-model="searchField" style="width: 100%;">
    </v-text-field>
    <v-list>
        <v-list-item v-for="item in itemsToShow" @click.stop="$emit('onSelect', item)" dir="rtl">
            <EquipmentListItem :title="item.item_name" :img-src="item.img">
                <template #subcomponents>
                    <div v-if="item.individual_tracking">
                        {{ item.sku }}
                    </div>
                    <div v-else>
                        {{ `${item.quantity} יח׳` }}
                    </div>
                </template>
            </EquipmentListItem>
        </v-list-item>
    </v-list>
</template>