<script setup>
import AppBar from '../AppBar.vue'
import { errorSnackbar } from '@/app';
import ItemsList from './ItemsList.vue';
import AddManualItem from './AddManualItem.vue';
import {
    init, isAddingManualItem, currentWarehouse, postItems, submissionDialog, takeoutSelectedCount, itemsData,
    withdrawMode, WITHDRAW_MODE_REGULAR, WITHDRAW_MODE_PERSONAL, WITHDRAW_MODE_TRANSFER, loading
} from './withdraw';
import { useRouter, useRoute } from 'vue-router';
import { computed } from 'vue'
import Loading from '../Loading.vue';

const props = defineProps({
    warehouse: {
        type: Object,
        required: false
    },
    withdraw_mode: {
        type: Number,
        required: false
    },
    backRoute: {
        type: String,
        required: true
    }
})

const router = useRouter()
const submit = async (force = false) => {
    for (const item of Object.values(itemsData.value).flat()) {
        if (item.details.checked_out || !item.state.selected) {
            continue
        }

        if (item.details.individual_tracking && !item.state.selected_sku) {
            errorSnackbar.value.open(`יש לבחור מק״ט`)
            return
        } else if (!item.details.individual_tracking && item.state.srcWarehouses.length < 1) {
            errorSnackbar.value.open(`יש להוסיף מחסן למשיכה`)
            return
        } else if (item.state.srcWarehouses.length >= 1 && item.state.srcWarehouses.some((source) => source.name == undefined)) {
            errorSnackbar.value.open(`יש לבחור מחסן למשיכה`)
            return
        } else if (withdrawMode === WITHDRAW_MODE_TRANSFER && !item.state.destWarehouse) {
            errorSnackbar.value.open(`יש לבחור מחסן יעד`)
            return
        }
    }

    if (withdrawMode == WITHDRAW_MODE_REGULAR &&
        !force && currentWarehouse.takeout_list &&
        takeoutSelectedCount.value < currentWarehouse.takeout_list.length) {
        submissionDialog.value = true
        return
    }

    loading.value = true
    
    await postItems()
    
    switch(withdrawMode) {
        case WITHDRAW_MODE_REGULAR:
            router.push(`/manage-activity-warehouse/${currentWarehouse.id}`)
            break
        case WITHDRAW_MODE_PERSONAL:
            router.push('/personal-activity')
            break
        case WITHDRAW_MODE_TRANSFER:
            router.push('/')
            break
    }

    loading.value = false
}

const nothingSelected = computed(() => {
    return !Object.values(itemsData.value).flat().some((item) => item.state.selected && !item.details.checked_out)
})



init(props.warehouse, props.withdraw_mode)
</script>

<template>
    <Loading v-if="loading"></Loading>
    <div v-else>
        <div v-if="isAddingManualItem">
            <AddManualItem></AddManualItem>
        </div>
        <div v-else>
            <AppBar @back="$router.push(backRoute)"
                :title="['משיכת ציוד לפעילות', 'משיכת ציוד אישי', 'העברה בין מחסנים'][withdrawMode]"
                :subtitle="(withdrawMode === WITHDRAW_MODE_REGULAR) ? currentWarehouse.name : ''"></AppBar>

            <ItemsList></ItemsList>

            <v-dialog persistent v-model="submissionDialog" style="width: 100%; max-width: 700px;">
                <v-card rounded="xl" style="text-align: center; padding-block: 18px; background: white;">
                    <v-icon icon="mdi-list-status" style="margin: auto; padding-block: 24px;"></v-icon>
                    <v-card-title style="font-size: 25px !important; margin-bottom: 0.8rem;">
                        יש ציוד שלא סימנת
                    </v-card-title>
                    <v-card-subtitle
                        style="white-space:normal; width: 95%; padding-right: auto; direction: rtl; font-size: 0.95rem !important; margin-bottom: 2.5rem;">
                        <div style="text-align: right;">
                            {{ currentWarehouse.takeout_list.length - takeoutSelectedCount }} פריטים מהרשימה שהוכנה עבורך
                            נשארו
                            לא מסומנים - לבצע משיכת ציוד בלעדיהם?
                        </div>
                    </v-card-subtitle>

                    <template #actions>
                        <v-btn @click="() => {
                            submit(true)
                            submissionDialog = false
                        }" variant="outlined" rounded="xl"
                            style="height: 45px; padding-inline: 20px; position: absolute; left: 5%;">
                            <span style="font-size: medium; font-weight: bold; color: #A8372C;">כן, הכל טוב</span>
                        </v-btn>
                        <v-btn @click="submissionDialog = false" style="height: 45px; position: absolute; right: 5%;">
                            <span style="font-size: medium; font-weight: bold; color: #A8372C;">לא, רגע אבדוק שוב</span>
                        </v-btn>
                    </template>
                </v-card>
            </v-dialog>

            <v-btn variant="outlined" rounded="xl"
                style="font-size: large; height: 50px; float: right; margin-top: 2rem; margin-bottom: 7rem; margin-right: 1rem; border-color: #857370; border-width: 1.3px;"
                @click="isAddingManualItem = true">
                <span style="color: #A8372C;">הוספת פריט +</span>
            </v-btn>
            <v-btn v-if="!nothingSelected" elevation="0" color="#A8372C" rounded="xl" @click="submit()"
                style="z-index: 1; position:fixed; bottom: 20px; left: 20px; font-size: large; height: 50px;">
                <div style="color: white; font-weight: bold;">
                    <span v-if="withdrawMode != WITHDRAW_MODE_TRANSFER">
                        ביצוע משיכת ציוד
                    </span>
                    <span v-else>
                        ביצוע העברה
                    </span>
                </div>
            </v-btn>
        </div>
    </div>
</template>

<style scoped>.v-btn {
    letter-spacing: 0.1px;
}</style>