<script setup>
import { ref } from 'vue'
import { postReport } from '../../useAPI'
import { successSnackbar, errorSnackbar } from '../../app'
import Loading from './Loading.vue';

const REPORT_TYPE_LOST = 'Lost'
const REPORT_TYPE_DAMAGED = 'Malfunction'

const showDialog = ref(false)
const reportType = ref(undefined)
const itemID = ref(undefined)
const reportDescription = ref('')
const loading = ref(false)

const open = (type, id) => {
    reportType.value = type
    itemID.value = id
    reportDescription.value = ''
    showDialog.value = true
    loading.value = false
}

const close = () => {
    showDialog.value = false
}

const submit = async () => {
    const report = {
        Inventory_Item: itemID.value,
        Type_field: reportType.value,
        Description: reportDescription.value
    }

    loading.value = true
    const success = await postReport(report)

    if (success) {
        successSnackbar.value.open('הדיווח שלך נשלח למערכת')
    } else {
        errorSnackbar.value.open('שגיאה בשליחת הדיווח. נסה שוב')
    }

    close()
}

defineExpose({
    REPORT_TYPE_LOST,
    REPORT_TYPE_DAMAGED,
    open,
    close
})
</script>

<template>
    <v-dialog persistent v-model="showDialog" style="max-width: 700px;">
        <v-card rounded="xl" style="text-align: center; padding-block: 18px; background: white;">
            <v-card-title style="font-size: 25px !important; margin-bottom: 0.8rem;">
                <span v-if="reportType == REPORT_TYPE_LOST">
                    דיווח על אובדן פריט
                </span>
                <span v-else-if="reportType == REPORT_TYPE_DAMAGED">
                    דיווח על תקלה בפריט
                </span>
            </v-card-title>

            <v-locale-provider rtl>
                <v-textarea style="margin-inline: 20px;" v-model:model-value="reportDescription" label="תיאור"
                    variant="outlined"></v-textarea>
            </v-locale-provider>

            <template #actions>
                <v-btn @click="() => {
                    if(!loading){
                        submit()
                    }
                }" variant="outlined" rounded="xl"
                    style="height: 45px; padding-inline: 20px; position: absolute; left: 5%;">
                    <v-progress-circular v-if="loading" indeterminate :size="30" :width="3" color="#A8372C" bg-color="white">
                    </v-progress-circular>
                    <span v-else style="font-size: medium; font-weight: bold; color: #A8372C;">שלח</span>
                </v-btn>
                <v-btn @click="showDialog = false" style="height: 45px; position: absolute; right: 5%;">
                    <span style="font-size: medium; font-weight: bold; color: #A8372C;">יציאה</span>
                </v-btn>
            </template>
        </v-card>
    </v-dialog>
</template>

