<script setup>
import AppBar from '../essentials/AppBar.vue'
import EquipmentListItem from '../essentials/EquipmentListItem.vue';
import { warehouses } from './warehousesList'
import { ref, computed } from 'vue'

const selectedWarehouseName = ref(warehouses[0].name)
const warehouseToShow = computed(() => {
    return warehouses.find((w) => w.name == selectedWarehouseName.value)
})
</script>

<template>
    <AppBar @back="$router.push('/')" title="רשימות ציוד מחסנים ראשיים"></AppBar>
    <v-locale-provider rtl>
        <div style="width: fit-content; margin: auto;">
            <v-autocomplete rounded="lg" variant="solo" bg-color="#A8372C0D" flat :placeholder="'בחרו מחסן'"
                :items="warehouses.map((w) => w.name)" no-data-text="לא נמצאו מחסנים" v-model="selectedWarehouseName"
                style="width: 20rem; margin-top: 10px;">
            </v-autocomplete>
        </div>
    </v-locale-provider>
    <span v-if="warehouseToShow">
        <p dir="rtl" style="padding: 20px; font-size: medium; color: #201A19;">
            {{ warehouseToShow.note }}
        </p>
        <v-list>
            <v-list-item v-for="item in warehouseToShow.items">
                <EquipmentListItem :title="item.name" :img-src="item.img">
                    <template #subcomponents>
                        <v-card-subtitle style="padding-right: 0px;">
                            <p v-if="item.individual_tracking">
                                {{ item.sku }}
                                <br>
                            </p>
                            <p v-else-if="item.quantity > 1">
                                {{ item.quantity }} יח׳
                            </p>
                        </v-card-subtitle>
                    </template>
                    <template #actions>
                        <v-menu>
                            <template #activator="{ props }">
                                <v-btn style="position: absolute; left: 0px;" elevation="0" icon="mdi-dots-vertical"
                                    v-bind="props"></v-btn>
                            </template>
                            <v-list
                                style="background: linear-gradient(0deg, rgba(168, 55, 44, 0.08), rgba(168, 55, 44, 0.08)), #FFFBFF;">
                                <v-list-item @click="">
                                    <v-list-item-title>
                                        דיווח על תקלה בפריט
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="">
                                    <v-list-item-title>
                                        דיווח על אובדן פריט
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </EquipmentListItem>
            </v-list-item>
        </v-list>
    </span>
</template>

<style scoped>
.v-autocomplete>>>.v-field__overlay {
    background: var(--flows-material-colors-surfaces-light-surface-5, linear-gradient(0deg, rgba(168, 55, 44, 0.14) 0%, rgba(168, 55, 44, 0.14) 100%), #FFFBFF);
}

.v-autocomplete {
    font-weight: bold;
}

.v-list {
    width: 100%;
    float: right;
}

.v-card>>>.v-card-item {
    padding-right: 0px !important;
}
</style>