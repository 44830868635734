import {ref} from 'vue'

const successSnackbar = ref({
    visible: false,
    message: '',
    open(message) {
        this.message = message
        this.visible = true
    }
})

const errorSnackbar = ref({
    visible: false,
    message: '',
    open(message) {
        this.message = message
        this.visible = true
    }
})

export {successSnackbar, errorSnackbar}