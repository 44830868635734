<script setup>
const props = defineProps({
    imgSrc: {
        type: String,
        required: false
    },
    title: {
        type: String,
        required: true
    },
    quantity: {
        type: Number,
        required: false
    }
})
function imagehandller(img){
    // if img not contains hebrew letters
    // fetching image with the hebrew letters name not working 
    if(img && !(/[\u0590-\u05FF]/).test(img)){
        
        return "https://creator.zoho.com" + img;
    }
    return require('../../assets/no-image.png')
}
</script>

<template>
    <v-card elevation="0" dir="rtl" style="display: flex; background: transparent !important;">
        <template #prepend>
            <v-img width="4rem" height="4rem" :src="imagehandller(imgSrc)" class="rounded-lg">
                <template #placeholder>
                    <v-img width="4rem" height="4rem" :src="imagehandller(imgSrc)" class="rounded-lg">
                    </v-img>
                </template>
            </v-img>
        </template>
        <div style="display: block; padding-right: 0px; max-width: 70%; ">
            <v-card-title style="color: #201A19; font-size: large; padding-bottom: 0px; padding-right: 0px; white-space:break-spaces;
                        width: fit-content; text-align: right; display: flex;">
                <p style="white-space: nowrap; ">{{ title }}</p>
                <p v-if="quantity" style="direction: ltr; margin-right: 5px;">{{ ` ${quantity} x ` }}</p>
            </v-card-title>
            <v-locale-provider rtl>
                <slot name="subcomponents"></slot>
            </v-locale-provider>
        </div>
        <slot name="actions"></slot>
    </v-card>
</template>

<style scoped>
.v-card>>>.v-card-item {
    padding-left: 0px !important;
}
</style>